import { Icon, Label, Link } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import { SystemStyleObject } from '@styled-system/css';
import React, { useEffect, useState } from 'react';
import { useClient } from 'urql';

import getAccountIcon from './getAccountIcon.gql';
import { AccountIcon, Query } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { usePathTemplate } from '@Core/usePathTemplate';

const loggedInIconStyles: SystemStyleObject = {
  backgroundColor: 'white',
  borderRadius: 'rounded',
  width: ['28px', '34px'],
  height: ['28px', '34px'],
};

export const AccountNavigation: React.FC<ClassNameProps> = ({ className }) => {
  const { t } = useTranslation();
  const urqlClient = useClient();
  const accountPath = usePathTemplate('customeraccount');
  const [accountIcon, setAccountIcon] = useState<AccountIcon>();

  useEffect(() => {
    const fetchAccountIcon = async () => {
      const { data } = await urqlClient.query<Query>(getAccountIcon, {}).toPromise();

      setAccountIcon(data?.Content.accountIcon);
    };

    fetchAccountIcon();
  }, [urqlClient]);

  const useLoggedInIcon = accountIcon?.isLoggedIn && !!accountIcon.firstNameInitial;

  return (
    <Link
      aria-label={t('header.account')}
      className={className}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50px',
        width: '50px',
        textDecoration: 'none',
        borderRadius: 3,
        '&:hover': {
          backgroundColor: 'megaMenuButtonHoverBackground',
        },
      }}
      href={accountPath}
      asAnchor
    >
      <div
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...(useLoggedInIcon && loggedInIconStyles),
        }}
      >
        {useLoggedInIcon && accountIcon.firstNameInitial ? (
          <Label
            variant="mediumbold"
            sx={{ color: 'basePrimary' }}
          >
            {accountIcon.firstNameInitial}
          </Label>
        ) : (
          <Icon
            name="Content/Customer"
            size={['28', '36']}
            sx={{
              color: 'white',
            }}
          />
        )}
      </div>
    </Link>
  );
};
