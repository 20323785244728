import { Button, Clickable, Icon, Label, Link, ModalHeader } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { ClassNameProps } from '@ComponentProps';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useModal } from '@Components/Modal/useModal';
import { useAppContext } from '@Contexts/contexts';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';

interface CheckoutTelephoneProps extends ClassNameProps {
  telephoneNumber: string;
}

export const CheckoutTelephone: React.FC<CheckoutTelephoneProps> = ({
  telephoneNumber,
  className,
}) => {
  const { isWebView } = useAppContext();
  const { t } = useTranslation();
  const showOnMobileAndTablet = useFeatureFlag('PhoneOnMobAndTabHeader');
  const [isOpen, openPhoneModal, closePhoneModal] = useModal();

  return (
    <div
      className={className}
      sx={{
        display: showOnMobileAndTablet ? 'flex' : ['none', null, 'flex'],
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        maxWidth: '165px',
        whiteSpace: 'pre-line',
        ...(showOnMobileAndTablet && {
          position: ['absolute', null, 'static'],
          right: ['xs', '2xl'],
        }),
      }}
    >
      <Link
        href={`tel:${telephoneNumber}`}
        trackingAction="checkout-phonenumber"
        sx={{
          display: showOnMobileAndTablet ? ['none', 'initial'] : 'initial',
        }}
      >
        <div
          sx={{ display: 'flex', flexDirection: 'column', color: 'strokeWhite', textAlign: 'end' }}
        >
          <Label variant={['small', showOnMobileAndTablet ? 'mediumbold' : 'medium']}>
            {t(
              `checkout.header.offlineSalesPhone.${showOnMobileAndTablet ? 'bookByPhone' : 'newBookings'}`,
            )}
          </Label>
          <Label variant={['small', 'medium']}>
            {showOnMobileAndTablet && isWebView
              ? t('checkout.header.offlineSalesPhone.appNumber')
              : telephoneNumber}
          </Label>
        </div>
      </Link>
      <Clickable
        onClick={openPhoneModal}
        trackingAction="view-phone-number"
        eventLabel="header-phone-icon"
        sx={{
          display: showOnMobileAndTablet ? ['flex', 'none'] : 'none',
        }}
      >
        <Icon
          size="28"
          name="Content/InputPhone"
          color="strokeWhite"
        />
      </Clickable>
      <LoadableModal
        onClose={closePhoneModal}
        Header={
          <ModalHeader
            as="h3"
            layout="medium"
            label={t('checkout.header.offlineSalesPhone.bookByPhone')}
            onClose={closePhoneModal}
            sx={{ padding: 'xs' }}
          />
        }
        Content={
          <div
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2xs',
              paddingBottom: 'xs',
            }}
          >
            <Label variant="medium">
              {t('checkout.header.offlineSalesPhone.phoneAndHours', {
                phoneNumber: t(
                  `checkout.header.offlineSalesPhone.${isWebView ? 'appNumber' : 'mobileNumber'}`,
                ),
              })}
            </Label>
          </div>
        }
        show={isOpen}
        mobileFloating
        Actions={
          <Button
            size="48"
            variant="PrimaryLegacy"
            trackingAction="click-call-us"
            eventLabel="checkout-phone-modal"
            href={`tel:${t(
              `checkout.header.offlineSalesPhone.${isWebView ? 'appNumber' : 'mobileNumber'}`,
            )}`}
          >
            {t('checkout.header.offlineSalesPhone.callNow')}
          </Button>
        }
      />
    </div>
  );
};
